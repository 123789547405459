import { useSelector } from '@innedit/formidable';
import { CommandeModel } from '@innedit/innedit-react';
import { CommandeType, DocumentType } from '@innedit/innedit-type';
import { Link, PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout';
import { StateProps } from '../reducers';

const CommandesPage: FC<PageProps> = function ({ location: { pathname } }) {
  const { t } = useTranslation();
  const [commandes, setCommandes] = useState<DocumentType<CommandeType>[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { user } = useSelector((state: StateProps) => state);
  const authUser = user ? user.authUser : undefined;

  useEffect(() => {
    let isMounted = true;
    if (authUser?.uid) {
      CommandeModel.getAllByUserId(authUser.uid)
        .then(docs => {
          if (isMounted) {
            setCommandes(docs);
          }

          setIsLoading(false);

          return isMounted;
        })
        .catch(console.error);
    }

    return () => {
      isMounted = false;
    };
  }, [authUser?.uid]);

  return (
    <Layout pathname={pathname} showTitle title="pages.commandes.title">
      {isLoading && <div>Chargement en cours</div>}
      {commandes && commandes.length > 0 && (
        <ul>
          {commandes.map(commande => (
            <li key={commande.id}>
              <Link to={t('pages.commande.pathname', { id: commande.id })}>
                {commande.createdAt} - {commande.produits.length} produit
              </Link>
            </li>
          ))}
        </ul>
      )}
    </Layout>
  );
};

export default CommandesPage;
